import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
//import Img from 'gatsby-image';
import { GatsbyImage } from "gatsby-plugin-image"
// import Button from '../components/button';

const ProjectsPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`gatsby`, `application`, `react`, `portfolio`]}
    />
    <h4><i>Sites reliés à X-Memorial</i></h4>
    <div className="project-list">
      {data.allProjectsJson.edges.map(project => (
        <div key={project.node.id} className="project-list__item">
          <div className="project-list__thumbnail">
          <a href={project.node.url} target="_blank" rel="noreferrer" >
            <GatsbyImage image={project.node.thumbnailImage.childImageSharp.gatsbyImageData} alt="thumbnailImage"/>
          </a>
          </div>
          <div className="project-list__content">
            <a href={project.node.url} target="_blank" rel="noreferrer" >
            <h4>{project.node.title}</h4>
            </a>
            <div className="project-list__excerpt">
              {project.node.description}
            </div>           
            <hr></hr>
          </div>
        </div>
      ))}
    </div>
  </Layout>
);

export default ProjectsPage;

// ...your graphql query

export const projectsQuery = graphql`
  query {
    allProjectsJson(sort: { order: DESC, fields: [date] }) {
      edges {
        node {
          id
          title
          date
          description
          url
          thumbnailImage {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED,
                width: 150,
                height: 150,
                placeholder: BLURRED,
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

